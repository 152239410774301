<template>
  <div class="page not-found">
    <p>{{ $t('403.message', { clientName: clientName }) }}</p>
    <b-button
      variant="secondary"
      size="lg"
      @click="goBack"
    >
      <b-icon-arrow-bar-left />
      {{ $t('403.back') }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'AccessDeniedPage',

  data() {
    return {
      routeFrom: null
    }
  },

  computed: {
    clientName() {
      return process.env.VUE_APP_CLIENT_NAME;
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeFrom = process.env.VUE_APP_BASE_PATH + from.path || process.env.VUE_APP_BASE_PATH;
    });
  },

  methods: {
    goBack() {
      window.location.pathname = this.routeFrom;
    }
  }

}
</script>
